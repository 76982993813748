import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {Toast} from "primereact/toast";
import LoadingAnimation from "../../components/UI/LoadingAnimation/LoadingAnimation";
import {useTranslation} from "react-i18next";
import {repairGetDetails} from "../../services/RepairService";
import "./RepairEdit.css"
import {ConfirmDialog} from "primereact/confirmdialog";
import RepairCustomer from "./parts/RepairCustomer";
import RepairImages from "./parts/RepairImages";
import RepairStatusBar from "./parts/RepairStatusBar";
import RepairActions from "./parts/RepairActions";
import RepairTabs from "./parts/RepairTabs";
import {storeGetList} from "../../services/StoreService";
import {partnersGetList} from "../../services/PartnerService";
import hexToBase64 from "../../utils/hexToBase64";
import {userGetDetails} from "../../services/UserService";

export default function RepairEdit(props) {

    const {idRepair} = useParams();
    const [repair, setRepair] = useState(null);
    const [stores, setStores] = useState(null);
    const [user, setUser] = useState(null);
    const [partners, setPartners] = useState(null);
    const {t, i18n} = useTranslation();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [reloadRepairFlag, setReloadRepairFlag] = useState(false);

    const reloadRepair = () => {
        setReloadRepairFlag(!reloadRepairFlag)
    }

    const fetchData = async () => {
        try {
            setLoading(true)

            // load repair
            const repairData = await repairGetDetails(idRepair);
            setRepair(repairData);

            // load store configuration
            const userData = await userGetDetails();
            setUser(userData);

            // load stores
            const storesData = await storeGetList();
            setStores(storesData);

            // load partners
            const partnersData = await partnersGetList();
            setPartners(partnersData.partnerList);

            setLoading(false)
        } catch (error) {
            setLoading(false)
            alert('Failed to fetch customer data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, [idRepair,reloadRepairFlag]);

    return <>
        <ConfirmDialog/>
        <LoadingAnimation state={loading}/>
        <Toast ref={toast} position="bottom-right"/>

        <div className={"flex gap-5"}>
            <div className={"left-col flex flex-col gap-5"}>
                <RepairCustomer repair={repair} user={user}/>
                <RepairImages repair={repair} toast={toast} setLoading={setLoading} reloadRepair={reloadRepair}/>
                <div className={"bg-white p-5 rounded-md flex items-center gap-5 service-item-images"}>
                    <img className={"w-1/2"} src={`data:image/png;base64,${repair && repair.qr && hexToBase64(repair.qr.bytes)}`}/>
                    <span>{t("View in the app")}</span>
                </div>
            </div>

            <div className={"right-col w-5/6"}>
                <RepairTabs repair={repair} toast={toast} setLoading={setLoading} reloadRepair={reloadRepair}
                            stores={stores} partners={partners} user={user}/>
            </div>
        </div>

        <div className={"floating-bar flex items-center px-10 py-5 gap-5"}>
            <RepairStatusBar repair={repair}/>
            <RepairActions repair={repair} toast={toast} setLoading={setLoading} reloadRepair={reloadRepair}/>
        </div>
    </>

}
