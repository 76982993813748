import {Panel} from "primereact/panel";
import FormControl from "../../components/UI/FormControl";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import {useTranslation} from "react-i18next";
import {repairCreate, repairCreateRecurringCustomer} from "../../services/RepairService";
import {userGetDetails} from "../../services/UserService";
import RepairCreateCustomer from "./parts/RepairCreateCustomer";
import {InputSwitch} from "primereact/inputswitch";
import {deviceTypesGetList} from "../../services/DeviceTypeService";
import {modelsGetList} from "../../services/ModelsService";
import {brandsGetDetails, brandsGetList} from "../../services/BrandsService";
import {AutoComplete} from "primereact/autocomplete";
import {TabPanel, TabView} from "primereact/tabview";

export default function RepairCreate(props) {
    const toast = useRef(null);
    const [storeConfiguration, setStoreConfiguration] = useState(false);

    const [description, setDescription] = useState("");
    const [customerName, setCustomerName] = useState(null);
    const [customerTelephone, setCustomerTelephone] = useState(null);
    const [customerTelephone2, setCustomerTelephone2] = useState(null);


    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [itemDescription, setItemDescription] = useState(null);
    const [paid, setPaid] = useState(false);
    const [price, setPrice] = useState(null);
    const [payInAdvance, setPayInAdvance] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showPayInAdvance, setShowPayInAdvance] = useState(false);
    const [serialNumber, setSerialNumber] = useState(null);
    const [securityCode, setSecurityCode] = useState(null);
    const [hasWarranty, setHasWarranty] = useState(false);
    const [deviceType, setDeviceType] = useState(null);
    const [model, setModel] = useState(null);
    const [brand, setBrand] = useState(null);
    const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [brandOptions, setBrandOptions] = useState([]);
    const [deviceTypeAutocompleteSuggestions, setDeviceTypeAutocompleteSuggestions] = useState([]);
    const [modelAutocompleteSuggestions, setModelAutocompleteSuggestions] = useState([]);
    const [brandAutocompleteSuggestions, setBrandAutocompleteSuggestions] = useState([]);
    const [productDescriptionTypeTabIndex, setProductDescriptionTypeTabIndex] = useState(0);
    const {t, i18n} = useTranslation();

    const fetchStoreConfiguration = async () => {
        setLoading(true)
        try {
            const userDetails = await userGetDetails()
            setStoreConfiguration(userDetails.store.configuration)
            if (userDetails.store.configuration.hasDeviceBrandModelProducts) {
                setProductDescriptionTypeTabIndex(1)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alert('Failed to fetch user data:', error);
        }
    }
    const fetchDeviceTypeOptions = async () => {
        try {
            const deviceOptionsPage = await deviceTypesGetList();
            const returnArray = [];
            deviceOptionsPage.content.map((deviceOption) => {
                returnArray.push({
                    "label": deviceOption.value,
                })
            })
            setDeviceTypeOptions(returnArray)
        } catch (error) {
            alert('Failed to fetch device options:', error);
        }
    }

    const fetchModelOptions = async () => {
        try {
            const modelsPage = await modelsGetList();
            const returnArray = [];
            modelsPage.content.map((model) => {
                returnArray.push({
                    "label": model.value,
                })
            })
            setModelOptions(returnArray)
        } catch (error) {
            alert('Failed to fetch model options:', error);
        }
    }
    const fetchBrandOptions = async () => {
        try {
            const brandsPage = await brandsGetList();
            const returnArray = [];
            brandsPage.content.map((brand) => {
                returnArray.push({
                    "label": brand.value,
                })
            })
            setBrandOptions(returnArray)
        } catch (error) {
            alert('Failed to fetch model options:', error);
        }
    }

    useEffect(() => {
        fetchStoreConfiguration();
        fetchBrandOptions();
        fetchModelOptions();
        fetchDeviceTypeOptions();
    }, []);

    const validateMandatoryFields = () => {
        if (!customerName && !selectedCustomer) {
            toast.current.show({
                severity: 'warn',
                summary: t("Something went wrong"),
                detail: t("Please fill in the customer's name"),
                life: 3000,
                position: "top"
            });
            return false
        }
        if (!customerTelephone && !selectedCustomer) {
            toast.current.show({
                severity: 'warn',
                summary: t("Something went wrong"),
                detail: t("Please fill in the customer's phone number"),
                life: 3000,
                position: "top"
            });
            return false
        }
        if (!description) {
            toast.current.show({
                severity: 'warn',
                summary: t("Something went wrong"),
                detail: t("Please fill in the repair's description"),
                life: 3000,
                position: "top"
            });
            return false
        }
        return true
    }

    const formSubmit = async (e) => {
        e.preventDefault()

        if (!validateMandatoryFields()) {
            return "";
        }

        setLoading(true)
        try {
            let payInAdvanceAmount = payInAdvance
            if (!showPayInAdvance) {
                payInAdvanceAmount = 0
            }

            let response
            if (selectedCustomer == null) {
                response = await repairCreate({
                    "customerName": customerName,
                    "customerTelephone": customerTelephone,
                    "customerTelephone2": customerTelephone2,
                    "description": description,
                    "price": !price ? 0 : parseFloat(price),
                    "payInAdvanceAmount": parseFloat(payInAdvanceAmount),
                    "serialNumber": serialNumber,
                    "securityCode": securityCode,
                    "hasWarranty": hasWarranty,
                    "productType": productDescriptionTypeTabIndex === 0 ? "SIMPLE" : "DEVICE_BRAND_MODEL",
                    "itemDescription": itemDescription,
                    "model": model,
                    "device": deviceType,
                    "brand": brand,
                });
            } else {
                response = await repairCreateRecurringCustomer({
                    "idCustomer": selectedCustomer.id,
                    "description": description,
                    "price": !price ? 0 : parseFloat(price),
                    "payInAdvanceAmount": parseFloat(payInAdvanceAmount),
                    "serialNumber": serialNumber,
                    "securityCode": securityCode,
                    "hasWarranty": hasWarranty,
                    "productType": productDescriptionTypeTabIndex === 0 ? "SIMPLE" : "DEVICE_BRAND_MODEL",
                    "itemDescription": itemDescription,
                    "model": model,
                    "device": deviceType,
                    "brand": brand,
                });
            }
            setLoading(false)

            if (storeConfiguration.adminPanelPrintingType === "a4") {
                window.open(`/repair/${response.id}/delivery-receipt`, '_blank').focus();
            } else if (storeConfiguration.adminPanelPrintingType === "a5") {
                window.open(`/repair/${response.id}/delivery-receipt-a5`, '_blank').focus();
            }

            window.location.href = "/repair/list"

        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t("Something went wrong"),
                detail: t("Something went wrong while creating new repair"),
                life: 3000,
                position: "top"
            });
            setLoading(false)
            return
        }

        window.location.href = "/repair/list"
    }


    const searchDeviceType = (event) => {
        const returnArray = [];
        deviceTypeOptions.map((deviceTypeOption) => {
            if (deviceTypeOption.label.toUpperCase().includes(event.query.toUpperCase())) {
                returnArray.push(deviceTypeOption)
            }
        })
        setDeviceTypeAutocompleteSuggestions(returnArray)
    }

    const searchBrands = (event) => {
        const returnArray = [];
        brandOptions.map((object) => {
            if (object.label.toUpperCase().includes(event.query.toUpperCase())) {
                returnArray.push(object)
            }
        })
        setBrandAutocompleteSuggestions(returnArray)
    }
    const searchModels = (event) => {
        const returnArray = [];
        modelOptions.map((object) => {
            if (object.label.toUpperCase().includes(event.query.toUpperCase())) {
                returnArray.push(object)
            }
        })
        setModelAutocompleteSuggestions(returnArray)
    }

    return <>
        <Toast ref={toast} position="top-right"/>
        <div>
            <div className={"flex gap-10 mb-10"}>
                <div className={"flex gap-10 flex-col w-full"}>
                    <RepairCreateCustomer
                        setCustomerName={setCustomerName} setCustomerTelephone={setCustomerTelephone}
                        setCustomerTelephone2={setCustomerTelephone2} setLoading={setLoading}
                        customerTelephone={customerTelephone} customerTelephone2={customerTelephone2}
                        customerName={customerName} toast={toast} selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer} storeConfiguration={storeConfiguration}
                    />
                    <Panel header={t("Pricing")} className={"w-full"}>
                        <div className={"flex gap-5"}>
                            <FormControl label={t("Repair amount")}>
                                <div className="p-inputgroup flex-1 ">
                                    <InputText placeholder={t("Repair amount")} keyfilter="money"
                                               onChange={(e) => {
                                                   setPrice(e.target.value.replace(",", "."))
                                               }}
                                    />
                                    <span className="p-inputgroup-addon">€</span>
                                </div>
                            </FormControl>
                            <FormControl label={t("Pay In Advance Amount / Inspection Cost")}
                                         className={payInAdvance === 0 && "invisible"}>
                                <div className="p-inputgroup flex-1 ">
                                    <InputText placeholder={t("Pay In Advance Amount / Inspection Cost")}
                                               keyfilter="money" value={payInAdvance}
                                               onChange={(e) => {
                                                   setPayInAdvance(e.target.value.replace(",", "."))
                                               }}
                                    />
                                    <span className="p-inputgroup-addon">€</span>
                                </div>
                            </FormControl>
                        </div>

                        <div className="flex items-center mb-4 gap-10">
                            <div className="flex items-center gap-2">
                                <Checkbox inputId="payinadvance" onChange={e => {
                                    setShowPayInAdvance(e.checked)
                                    if (e.checked) {
                                        setPaid(false)
                                        setPayInAdvance(null)
                                    } else {
                                        setPaid(false)
                                        setPayInAdvance(0)
                                    }
                                }}
                                          checked={showPayInAdvance}></Checkbox>
                                <label htmlFor="payinadvance"
                                       className="cursor-pointer text-base text-gray-500">{t("Pay In Advance / Inspection Cost")}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Checkbox inputId="paid" onChange={e => {
                                    setPaid(e.checked)
                                    if (e.checked) {
                                        setShowPayInAdvance(false)
                                        setPayInAdvance(price != null ? price : 1)
                                    } else {
                                        setShowPayInAdvance(false)
                                        setPayInAdvance(0)
                                    }
                                }}
                                          checked={paid}></Checkbox>
                                <label htmlFor="paid"
                                       className="cursor-pointer text-base text-gray-500">{t("Paid")}</label>
                            </div>
                        </div>
                    </Panel>
                </div>

                <div className={"w-full flex flex-col gap-5"}>
                    <Panel header={t("Repair Product")} className={"w-full"}>
                        <TabView activeIndex={productDescriptionTypeTabIndex}
                                 onTabChange={(e) => setProductDescriptionTypeTabIndex(e.index)}
                        >
                            <TabPanel header={t("Description")}>
                                <FormControl label={t("Product to be repaired")}>
                                    <div className="card flex justify-content-center">
                                        <InputText placeholder={t("Product to be repaired")} className={"w-full"}
                                                   onChange={(e) =>
                                                       setItemDescription(e.target.value)}
                                        />
                                    </div>
                                </FormControl>
                            </TabPanel>
                            <TabPanel header={t("Device-Brand-Model")}>
                                <div className={"flex gap-5"}>
                                    <FormControl label={t("Device Type")}>
                                        <AutoComplete value={deviceType} suggestions={deviceTypeAutocompleteSuggestions}
                                                      completeMethod={searchDeviceType}
                                                      field="label" dropdown placeholder={t("Device Type")}
                                                      onChange={(e)=>{
                                                          if(typeof e.value === 'object') {
                                                              setDeviceType(e.value.label)
                                                              return
                                                          }
                                                          setDeviceType(e.value)
                                                      }}/>
                                    </FormControl>
                                    <FormControl label={t("Brand")}>
                                        <AutoComplete value={brand} suggestions={brandAutocompleteSuggestions}
                                                      completeMethod={searchBrands}
                                                      field="label" dropdown placeholder={t("Brand")}
                                                      onChange={(e)=>{
                                                          if(typeof e.value === 'object') {
                                                              setBrand(e.value.label)
                                                              return
                                                          }
                                                          setBrand(e.value)
                                                      }}/>
                                    </FormControl>
                                    <FormControl label={t("Model")}>
                                        <AutoComplete value={model} suggestions={modelAutocompleteSuggestions}
                                                      completeMethod={searchModels}
                                                      field="label" dropdown placeholder={t("Model")}
                                                      onChange={(e) => {
                                                          if(typeof e.value === 'object') {
                                                              setModel(e.value.label)
                                                              return
                                                          }
                                                          setModel(e.value)
                                                      }}/>
                                    </FormControl>
                                </div>
                            </TabPanel>
                        </TabView>
                        <div className={"flex gap-5"}>
                            {storeConfiguration && storeConfiguration.hasSecurityCode &&
                                <FormControl label={t("Security Code")}>
                                    <div className="card flex justify-content-center">
                                        <InputText placeholder={t("Security Code")} className={"w-full"}
                                                   onChange={(e) =>
                                                       setSecurityCode(e.target.value)}
                                        />
                                    </div>
                                </FormControl>
                            }
                            {storeConfiguration && storeConfiguration.hasSerialNumber &&
                                <FormControl label={t("Serial Number")}>
                                    <div className="card flex justify-content-center">
                                        <InputText placeholder={t("Serial Number")} className={"w-full"}
                                                   onChange={(e) =>
                                                       setSerialNumber(e.target.value)}
                                        />
                                    </div>
                                </FormControl>
                            }
                        </div>
                    </Panel>
                    <Panel header={t("Malfunction")} className={"w-full"}>
                        {storeConfiguration && storeConfiguration.hasWarranty && <FormControl label={t("Warranty")}>
                            <div className="card flex justify-content-center items-center gap-3">
                                <InputSwitch checked={hasWarranty} onChange={(e) => setHasWarranty(e.value)}/>
                                <span
                                    className={"text-sm text-gray-500"}>{t("Is the repair covered by a warranty?")}</span>
                            </div>
                        </FormControl>}

                        <FormControl label={t("Malfunction Description")} required={true}>
                            <div className="card flex justify-content-center">
                                <InputTextarea value={description} className={"w-full"} placeholder={t("Description")}
                                               onChange={(e) => setDescription(e.target.value)}
                                               rows={5} cols={50}/>
                            </div>
                        </FormControl>
                    </Panel>
                </div>
            </div>
            <div className={"flex justify-end"}>
                <Button label={t("Create Repair")} loading={loading} disabled={loading} onClick={formSubmit}/>
            </div>
        </div>
    </>
}
