import {callApiWithAuthentication, callApiWithOutAuthentication} from "../utils/callApiWithAuthentication";

export async function leadCreate(requestBody) {
    try {
        const response = await callApiWithOutAuthentication({
            method: "post",
            url: `${window.API_URL}/leads`,
            data: requestBody
        });
        return response
    } catch (error) {
        throw error;
    }
}

export async function leadUpdate(idLead, requestBody) {
    try {
        const response = await callApiWithOutAuthentication({
            method: "put",
            url: `${window.API_URL}/leads/${idLead}/`,
            data: requestBody
        });
        return response
    } catch (error) {
        throw error;
    }
}
