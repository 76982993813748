import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FormControl from "../../components/UI/FormControl";
import {useTranslation} from "react-i18next";
import {partnerCreate} from "../../services/PartnerService";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {technicianCreate} from "../../services/TechnicianService";
import {deviceTypeCreate} from "../../services/DeviceTypeService";
import {brandsCreate} from "../../services/BrandsService";
import {modelsCreate} from "../../services/ModelsService";

export default function ModelCreate(props) {

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const formSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await modelsCreate({
                "value": value,
            });
        } catch (error) {
            alert("error");
            setLoading(false)
            return
        }

        setLoading(false)
        navigate("/models/list")
    }

    return <Panel header={t("New Model")}>
        <form onSubmit={formSubmit}>
            <div className={"flex gap-4"}>
                <FormControl label={t("Model")} required={true}>
                    <InputText placeholder={t("Model")} name={"value"} value={value}
                               onChange={(e) => {
                                   setValue(e.target.value)
                               }}/>
                </FormControl>
            </div>
            <div className={"flex justify-end"}>
                <Button label={t("Create Model")} loading={loading}/>
            </div>
        </form>
    </Panel>
}
