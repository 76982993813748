import Input from "../../components/UI/Input/Input";
import {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as UserInputIcon} from "./../../assets/UserInputIcon.svg";
import {ProgressBar} from 'primereact/progressbar';
import styles from "./Register.module.css";
import {FaArrowLeft, FaArrowRight, FaBuilding, FaCheck, FaNetworkWired, FaPhone, FaStore} from "react-icons/fa";
import {leadCreate, leadUpdate} from "../../services/LeadService";
import RegisterStep from "./RegisterStep";
import {RiToolsFill} from "react-icons/ri";
import {Link} from "react-router-dom";

export default function RegisterForm(props) {

    const {t, i18n} = useTranslation();
    const [step, setStep] = useState(1)
    const [lead, setLead] = useState(null)
    const emailRef = useRef();
    const phoneNumberRef = useRef();
    const nameRef = useRef();
    const companyNameRef = useRef();
    const occupationRef = useRef();
    const storeCountRef = useRef();
    const interestInErpIntegrationRef = useRef();

    const onSubmitStep1 = async (to) => {
        try {
            let response;
            if (lead == null) {
                response = await leadCreate({
                    "email": emailRef.current.getValue()
                });
            } else {
                response = await leadUpdate(lead.id, {
                    "email": emailRef.current.getValue(),
                });
            }
            setLead(response)
        } catch (error) {
            alert("error");
        }
    }

    const onSubmitStep2 = async (to) => {
        try {
            lead.phoneNumber = phoneNumberRef.current.getValue()
            const response = await leadUpdate(lead.id, lead);
            setLead(response);
        } catch (error) {
            alert("error");
        }
    }

    const onSubmitStep3 = async (to) => {
        try {
            lead.name = nameRef.current.getValue()
            const response = await leadUpdate(lead.id, lead);
            setLead(response);
        } catch (error) {
            alert("error");
        }
    }
    const onSubmitStep4 = async (to) => {
        try {
            lead.companyName = companyNameRef.current.getValue()
            const response = await leadUpdate(lead.id, lead);
            setLead(response);
        } catch (error) {
            alert("error");
        }
    }
    const onSubmitStep5 = async (to) => {
        try {
            lead.occupation = occupationRef.current.getValue()
            const response = await leadUpdate(lead.id, lead);
            setLead(response);
        } catch (error) {
            alert("error");
        }
    }
    const onSubmitStep6 = async (to) => {
        try {
            lead.storeCount = storeCountRef.current.getValue()
            const response = await leadUpdate(lead.id, lead);
            setLead(response);
        } catch (error) {
            alert("error");
        }
    }
    const onSubmitStep7 = async (to) => {
        try {
            lead.interestInErpIntegration = interestInErpIntegrationRef.current.getValue()
            lead.completed = true
            const response = await leadUpdate(lead.id, lead);
            setLead(response);
        } catch (error) {
            alert("error");
        }
    }

    return (
        <div className={"w-full overflow-hidden"}>

            {step !== 8 && <h1 className={"text-white text-4xl font-semibold text-center mb-16"}>{t("Register")}</h1>}

            <RegisterStep step={1} currentStep={step} setStep={setStep} onNext={onSubmitStep1}
                          label={t("Please enter your E-mail")}>
                <Input
                    ref={emailRef}
                    IconComponent={UserInputIcon}
                    label={t("E-mail")}
                    type={"text"}
                />
            </RegisterStep>

            <RegisterStep step={2} currentStep={step} setStep={setStep} onNext={onSubmitStep2}
                          label={t("Please enter your Phone Number")}>
                <Input
                    ref={phoneNumberRef}
                    IconComponent={FaPhone}
                    label={t("Phone Number")}
                    type={"text"}
                />
            </RegisterStep>

            <RegisterStep step={3} currentStep={step} setStep={setStep} onNext={onSubmitStep3}
                          label={t("Please enter your Full Name")}>
                <Input
                    ref={nameRef}
                    IconComponent={UserInputIcon}
                    label={t("Full Name")}
                    type={"text"}
                />
            </RegisterStep>
            <RegisterStep step={4} currentStep={step} setStep={setStep} onNext={onSubmitStep4}
                          label={t("What's your Company called?")}>
                <Input
                    ref={companyNameRef}
                    IconComponent={FaBuilding}
                    label={t("Company Name")}
                    type={"text"}
                />
            </RegisterStep>
            <RegisterStep step={5} currentStep={step} setStep={setStep} onNext={onSubmitStep5}
                          label={t("What is the primary business domain your company operates in?")}>
                <Input
                    ref={occupationRef}
                    IconComponent={RiToolsFill}
                    label={t("Business Field")}
                    type={"text"}
                />
            </RegisterStep>
            <RegisterStep step={6} currentStep={step} setStep={setStep} onNext={onSubmitStep6}
                          label={t("How many stores does your company have?")}>
                <Input
                    ref={storeCountRef}
                    IconComponent={FaStore}
                    label={t("Number of Stores")}
                    type={"text"}
                />
            </RegisterStep>
            <RegisterStep step={7} currentStep={step} setStep={setStep} onNext={onSubmitStep7}
                          label={t("Are you interested in an integration with some external software (ERP, CRM, etc)?")}>
                <Input
                    ref={interestInErpIntegrationRef}
                    IconComponent={FaNetworkWired}
                    label={t("Interest in External Integrations")}
                    type={"text"}
                />
            </RegisterStep>
            <RegisterStep step={8} currentStep={step} setStep={setStep}>
                <div className={"w-full flex items-center justify-center mb-20"}>
                    <div className={"w-20 h-20 flex items-center bg-white rounded-full justify-center p-5"}>
                        <FaCheck size={40} color={"#00BA98FF"}/>
                    </div>
                </div>
                <p className={"text-white text-center font-bold text-xl"}>{t("Thank you for your interest!")}</p>
                <p className={"text-white text-center "}>{t("Our team will prepare your demo and notify you via email.")}</p>
            </RegisterStep>
            <Link to={"/login"} className={"text-white mt-5 text-center block w-full"}>{t("Already have an account? Log in here")}</Link>

            {
                step !== 8 &&
                <div className={styles.progressBarContainer}>
                    <p className={"text-lg text-white"}>{t("Progress")}</p>
                    <ProgressBar value={Math.round((step - 1) * 100 / 7)} className={styles.progressBar}/>
                </div>
            }


            <div className={"absolute w-5/12 aspect-square rounded-full bg-white top-20 -right-10 opacity-50 sm:hidden z-0"}></div>
            <div className={"absolute w-8/12 aspect-square rounded-full bg-white top-50 -left-20 opacity-50 sm:hidden z-0"}></div>
        </div>
    )
}
