import {Link} from "react-router-dom";
import React from "react";
import {ReactComponent as UserIcon} from "./../../../assets/UserIcon.svg"
import {filterNullValues} from "../../../utils/filterNullValues";

const RepairCustomer = ({repair, user}) => {
    const getPhoneNumber2 = () => {
        if (!repair) return;
        if (!user) return;
        if (!user.store) return;
        if (!user.store.configuration) return;
        if (!repair.customer.phoneNumber2) return;
        if (repair.customer.phoneNumber2 == "null") return;
        return " - " + user.store.configuration.smsDefaultPrefix + repair.customer.phoneNumber2
    }
    const getPhoneNumber1 = () => {
        if (!repair) return;
        if (!user) return;
        if (!user.store) return;
        if (!user.store.configuration) return;
        if (repair.customer.phoneNumber1 == "null") return;
        return "+" + user.store.configuration.smsDefaultPrefix + " " + repair.customer.phoneNumber1
    }

    return <Link to={`/customer/${repair && repair.customer.id}/edit`}
                 className={"bg-white p-5 rounded-md flex flex-col items-center gap-5 "}>
        <UserIcon/>
        <div className={"text-center"}>
            <span className={"block text-lg font-bold"}>{repair && repair.customer.name}</span>
            <span className={"block text-gray-500 text-sm"}>
                {getPhoneNumber1()}
                {getPhoneNumber2()}
            </span>
        </div>
    </Link>
}

export default RepairCustomer
