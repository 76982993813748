import React, {useEffect, useState} from "react";
import {repairUpdate} from "../../../services/RepairService";
import {useTranslation} from "react-i18next";
import FormControl from "../../../components/UI/FormControl";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {ReactComponent as HistoryIcon} from "./../../../assets/HistoryIcon.svg";
import formatDate from "../../../utils/formatDate";
import {InputSwitch} from "primereact/inputswitch";
import {TabPanel, TabView} from "primereact/tabview";
import {AutoComplete} from "primereact/autocomplete";
import {filterNullValues} from "../../../utils/filterNullValues";


const RepairTabForm = ({repair, toast, setLoading, user}) => {
    const {t, i18n} = useTranslation();

    const [description, setDescription] = useState(null);
    const [type, setType] = useState(null);
    const [serialNumber, setSerialNumber] = useState(null);
    const [securityCode, setSecurityCode] = useState(null);
    const [hasOnlyOneLocationInHistory, setHasOnlyOneLocationInHistory] = useState(false);
    const [hasWarranty, setHasWarranty] = useState(false);
    const [deviceType, setDeviceType] = useState(null);
    const [model, setModel] = useState(null);
    const [brand, setBrand] = useState(null);
    const [productDescriptionTypeTabIndex, setProductDescriptionTypeTabIndex] = useState(0);



    useEffect(() => {
        if (!repair) return;
        if (repair.description) setDescription(filterNullValues(repair.description));
        if (repair.type) setType(filterNullValues(repair.type));
        if (repair.serialNumber) setSerialNumber(filterNullValues(repair.serialNumber));
        if (repair.securityCode) setSecurityCode(filterNullValues(repair.securityCode));
        if (repair.products && repair.products[0]) {
            setModel(filterNullValues(repair.products[0].model))
            setDeviceType(filterNullValues(repair.products[0].device));
            setBrand(filterNullValues(repair.products[0].brand));
            setProductDescriptionTypeTabIndex(repair.products[0].type == "DEVICE_BRAND_MODEL" ? 1 : 0)
        }
        if (repair.hasWarranty) setHasWarranty(repair.hasWarranty);
        setHasOnlyOneLocationInHistory(repair.history.length === 1)
    }, [repair]);

    const getHistoryText = (historyItem) => {
        const isFirst = historyItem.dateAdd === repair.history[0].dateAdd
        switch (historyItem.locationType) {
            case "STORE":
                return (isFirst ? t("Registered in store ") : t("Transferred to store ")) + historyItem.location.alias
            case "EXTERNAL_PARTNER":
                return t("Transferred to external partner ") + historyItem.location.alias
            case "COMMENT":
                return t("Technician Comments") + ": " + historyItem.comments
        }
    }

    const formSubmit = async (e) => {
        e.preventDefault()

        const productType = productDescriptionTypeTabIndex === 1 ? "DEVICE_BRAND_MODEL" : "SIMPLE";
        setLoading(true);
        try {
            await repairUpdate(repair.id, null, null, serialNumber, description,
                null, null, null, [], type, null, securityCode, null,
                hasWarranty, productType, model, deviceType, brand);
        } catch (error) {
            setLoading(false);
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατά την δημιουργία του πελάτη',
                life: 3000,
                position: "bottom"
            });
            return
        }

        setLoading(false);
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Repair updated successfully'),
            life: 3000
        });
    }

    return <div className={"flex gap-10 items-start"}>
        <form className={"w-full"}>
            <span className={"text-xl font-bold mb-2 block"}>{t("Repair Product")}</span>
            <TabView activeIndex={productDescriptionTypeTabIndex}
                     onTabChange={(e) => setProductDescriptionTypeTabIndex(e.index)}
            >
                <TabPanel header={t("Description")}>
                    <FormControl label={t("Product to be repaired")}>
                        <div className="card flex justify-content-center">
                            <InputText placeholder={t("Product to be repaired")} className={"w-full"} defaultValue={type}
                                       onChange={(e) =>
                                           setType(e.target.value)}
                            />
                        </div>
                    </FormControl>
                </TabPanel>
                <TabPanel header={t("Device-Brand-Model")}>
                    <div className={"flex gap-5"}>
                        <FormControl label={t("Device Type")}>
                            <InputText placeholder={t("Device Type")} className={"w-full"} defaultValue={deviceType}
                                       onChange={(e) =>
                                           setDeviceType(e.target.value)}
                            />
                        </FormControl>
                        <FormControl label={t("Brand")}>
                            <InputText placeholder={t("Brand")} className={"w-full"} defaultValue={brand}
                                       onChange={(e) =>
                                           setBrand(e.target.value)}
                            />
                        </FormControl>
                        <FormControl label={t("Model")}>
                            <InputText placeholder={t("Model")} className={"w-full"} defaultValue={model}
                                       onChange={(e) =>
                                           setModel(e.target.value)}
                            />
                        </FormControl>
                    </div>
                </TabPanel>
            </TabView>
            <div className={"flex gap-5"}>
                {user && user.store.configuration.hasSecurityCode &&
                    <FormControl label={t("Security Code")}>
                        <div className="card flex justify-content-center">
                            <InputText placeholder={t("Security Code")} className={"w-full"}
                                       defaultValue={securityCode}
                                       onChange={(e) =>
                                           setSecurityCode(e.target.value)}
                            />
                        </div>
                    </FormControl>
                }
                {user && user.store.configuration.hasSerialNumber &&
                    <FormControl label={t("Serial Number")}>
                        <div className="card flex justify-content-center">
                            <InputText placeholder={t("Serial Number")} className={"w-full"}
                                       defaultValue={serialNumber}
                                       onChange={(e) =>
                                           setSerialNumber(e.target.value)}
                            />
                        </div>
                    </FormControl>
                }
            </div>

            <span className={"text-xl font-bold mb-2 block"}>{t("Malfunction")}</span>
            {user && user.store.configuration.hasWarranty && <FormControl label={t("Warranty")}>
                <div className="card flex justify-content-center items-center gap-3">
                    <InputSwitch checked={hasWarranty} onChange={(e) => setHasWarranty(e.value)}/>
                    <span className={"text-sm text-gray-500"}>{t("Is the repair covered by a warranty?")}</span>
                </div>
            </FormControl>}

            <FormControl label={t("Description")} required={true}>
                <div className="card flex justify-content-center">
                    <InputTextarea defaultValue={description} className={"w-full"}
                                   placeholder={t("Description")}
                                   onChange={(e) => setDescription(e.target.value)}
                                   rows={5}/>
                </div>
            </FormControl>
            <div className={"text-right "}>
                <Button type="submit" icon="pi pi-save" className={"text-lg π-"} label={t("Save")}
                        onClick={formSubmit}/>
            </div>
        </form>
        <div className={"w-full"}>
            <span className={"text-xl font-bold mb-2 block"}>{t("Location History")}</span>
            <FormControl label={t("Location History")}>
                <div
                    className={`location-history flex ${!hasOnlyOneLocationInHistory && "flex-col"} justify-between relative`}>
                    {repair && !hasOnlyOneLocationInHistory && <div className={"horizontal-line"}></div>}
                    {repair && repair.history && repair.history.map((historyRecord) => {
                        return <div className={"location-history-item w-full rounded-md"}>
                            <div className={"flex items-center gap-4"}>
                                <HistoryIcon style={{width: "40px"}}/>
                                <div>
                                        <span className={"block text-md"}>
                                            {getHistoryText(historyRecord)}
                                        </span>
                                    <span className={"block text-gray-500 text-sm"}>
                                             {formatDate(historyRecord.dateAdd, true)}
                                        </span>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </FormControl>
        </div>
    </div>
}

export default RepairTabForm
