import classes from "./RegisterLayout.module.css";
import {ReactComponent as RegisterVector} from "./../../assets/RegisterVector.svg";
import {ReactComponent as LogoSvg} from "./../../assets/LogoSvg.svg";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";

export default function RegisterLayout(props) {
    return <>
        <div className={classes.container}>
            <div className={`${classes.left} w-full sm:w-4/12 overflow-hidden`}>
                <LogoSvg className={classes.logoSvg}/>
                <div className={"fixed top-6 right-6 sm:top-10 sm:right-10"}>
                    <div className={"hidden sm:block"}><LanguageSelector/></div>
                    <div className={"block sm:hidden"}><LanguageSelector noLabels={true}/></div>
                </div>
                {props.children}
            </div>
            <div className={`relative items-center justify-center hidden sm:flex w-8/12`}>
                <div className={classes.vectorContainer}>
                    <RegisterVector className={"w-7/12"}/>
                </div>
            </div>
        </div>
    </>
}
