import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import LoginForm from "../Login/components/LoginForm/LoginForm";
import LoadingAnimation from "../../components/UI/LoadingAnimation/LoadingAnimation";
import {useState} from "react";
import RegisterForm from "./RegisterForm";
import RegisterLayout from "../../layouts/RegisterLayout/RegisterLayout";

export default function Register() {

    const [loadingAnimationState, setLoadingAnimationState] = useState(false)

    return <div className={"login-page"}>
        <RegisterLayout>
            <RegisterForm setLoadingAnimationState={setLoadingAnimationState}/>
        </RegisterLayout>
        <LoadingAnimation state={loadingAnimationState}/>
    </div>
}
