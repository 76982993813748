import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FormControl from "../../components/UI/FormControl";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {partnerGetDetails, partnerUpdate} from "../../services/PartnerService";
import {useTranslation} from "react-i18next";
import {technicianGetDetails, technicianUpdate} from "../../services/TechnicianService";
import {deviceTypeCreate, deviceTypeGetDetails, deviceTypeUpdate} from "../../services/DeviceTypeService";
import {brandsGetDetails, brandsUpdate} from "../../services/BrandsService";

export default function BrandEdit(props) {

    const {id} = useParams();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await brandsGetDetails(id);
                setValue(data.value);
            } catch (error) {
                alert(t('Something went wrong'), error);
            }
        };

        fetch();
    }, []);

    const formSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await brandsUpdate(id, {
                "value": value,
            });
        } catch (error) {
            alert("error");
            setLoading(false)
            return
        }

        setLoading(false)
        navigate("/brands/list")
    }

    return <Panel header={t("Edit Brand")}>
        <form onSubmit={formSubmit}>
            <div className={"flex gap-4"}>
                <FormControl label={t("Brand")} required={true}>
                    <InputText placeholder={t("Brand")} name={"value"} value={value}
                               onChange={(e) => {
                                   setValue(e.target.value)
                               }}/>
                </FormControl>
            </div>
            <div className={"flex justify-end"}>
                <Button label={t("Update Brand")} loading={loading}/>
            </div>
        </form>
    </Panel>;
}
