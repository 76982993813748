import Input from "../../components/UI/Input/Input";
import {Button, ButtonVariation} from "../../components/UI/Button/Button";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";
import {useTranslation} from "react-i18next";

export default function RegisterStep(props) {
    const {t, i18n} = useTranslation();

    return <form className={`${props.step !== props.currentStep && "hidden"}`} onSubmit={(e) => {
        e.preventDefault();
        props.onNext();
        props.setStep(props.currentStep + 1)
    }}>
        <input type={"hidden"} name={"currentStep"} value={props.step}/>
        {props.label && <span className={"block text-white mb-4 sm:mb-3 text-lg"}>{props.label}</span>}
        {props.children}

        {props.currentStep !== 8 &&
            <Button
                variation={ButtonVariation.WHITE}
                text={t("Continue")}
                icon={<FaArrowRight size={20}/>}
            />}

        {![1, 8].includes(props.currentStep) &&
            <button className={"w-full mt-5 text-white flex justify-center items-center text-xl gap-5"}
                    onClick={(e) => {
                        e.preventDefault();
                        props.setStep(props.currentStep - 1)
                    }}>
                <FaArrowLeft size={20}/>
                <span>{t("Back")}</span>
            </button>}
    </form>
}
