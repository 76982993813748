import {callApiWithAuthentication} from "../utils/callApiWithAuthentication";


export const repairsGetList = async (page = 0, sort = "NEW", serviced = null, delivered = null) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/service-items?page=${page + 1}&sort=${sort}${serviced != null ? `&serviced=${serviced}` : ""}` +
                `${delivered != null ? `&delivered=${delivered}` : ""}`
        });
        return response
    } catch (error) {
        throw error;
    }
};
export const repairGetDetails = async (idRepair) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/service-item/${idRepair}`
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairsSearch = async (searchQuery) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/service-item/search?searchQuery=${searchQuery}`
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairSendSMS = async (idRepair) => {
    try {
        const response = await callApiWithAuthentication({
            method: "post",
            url: `${window.API_URL}/service-item/${idRepair}/sms`
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairDelete = async (idRepair) => {
    try {
        const response = await callApiWithAuthentication({
            method: "delete",
            url: `${window.API_URL}/service-item/${idRepair}/`
        });
        return response
    } catch (error) {
        throw error;
    }
};
export const repairUpdate = async (
    idRepair,
    customerName = null,
    customerTelephone = null,
    serialNumber = null,
    description = null,
    paid = null,
    price = null,
    payInAdvance = null,
    imagesToAdd = [],
    type = null,
    cost = null,
    securityCode = null,
    idTechnician = null,
    hasWarranty = null,
    productType = null,
    model = null,
    device = null,
    brand = null,
) => {
    try {
        const formData = new FormData();
        if (customerName != null) formData.append('customerName', customerName);
        if (customerTelephone != null) formData.append('customerTelephone', customerTelephone);
        if (serialNumber != null) formData.append('serialNumber', serialNumber);
        if (description != null) formData.append('description', description);
        if (paid != null) formData.append('paid', paid);
        if (price != null) formData.append('price', price);
        if (type != null) formData.append('type', type);
        if (payInAdvance != null) formData.append('payInAdvanceAmount', payInAdvance);
        if (cost != null) formData.append('cost', cost);
        if (securityCode != null) formData.append('securityCode', securityCode);
        if (idTechnician != null) formData.append('idTechnician', idTechnician);
        if (hasWarranty != null) formData.append('hasWarranty', hasWarranty);
        if (productType != null) formData.append('productType', productType);
        if (model != null) formData.append('model', model);
        if (device != null) formData.append('device', device);
        if (brand != null) formData.append('brand', brand);
        if (imagesToAdd.length > 0) {
            imagesToAdd.forEach((image, index) => {
                formData.append('imagesToAdd', image);
            });
        }
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/service-item/${idRepair}/`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'  // Make sure the content type is multipart/form-data
            }
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairMarkAsDelivered = async (idRepair) => {
    try {
        const formData = new FormData();
        formData.append('delivered', true);
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/service-item/${idRepair}/`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'  // Make sure the content type is multipart/form-data
            }
        });
        return response
    } catch (error) {
        throw error;
    }
};


export const repairMarkAsNotDelivered = async (idRepair) => {
    try {
        const formData = new FormData();
        formData.append('delivered', false);
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/service-item/${idRepair}/`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'  // Make sure the content type is multipart/form-data
            }
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairMarkAsServiced = async (idRepair) => {
    try {
        const formData = new FormData();
        formData.append('serviced', true);
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/service-item/${idRepair}/`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'  // Make sure the content type is multipart/form-data
            }
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairMarkServiceNull = async (idRepair) => {
    try {
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/service-item/${idRepair}/serviced?serviced=null`,
        });
        return response
    } catch (error) {
        throw error;
    }
}
export const repairMarkServiceFalse = async (idRepair) => {
    try {
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/service-item/${idRepair}/serviced?serviced=false`,
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairTransferToStore = async (idRepair, idStore, comments) => {
    try {
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/service-item/${idRepair}/transfer/`,
            data: {
                idLocation: idStore,
                comments: comments,
            }
        });
        return response
    } catch (error) {
        throw error;
    }
};
export const repairTransferToPartner = async (idRepair, idPartner, comments) => {
    // TODO Refactor
    try {
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/service-item/${idRepair}/transfer/`,
            data: {
                idLocation: idPartner,
                comments: comments,
            }
        });
        return response
    } catch (error) {
        throw error;
    }
};

export async function repairCreate(requestBody) {
    try {
        // Create a FormData object
        const formData = new FormData();

        // Append text fields to FormData
        formData.append('customerName', requestBody.customerName);
        formData.append('customerTelephone', requestBody.customerTelephone);
        formData.append('customerTelephone2', requestBody.customerTelephone2);
        formData.append('description', requestBody.description);
        formData.append('price', requestBody.price);
        formData.append('payInAdvanceAmount', requestBody.payInAdvanceAmount);
        formData.append('serialNumber', requestBody.serialNumber);
        formData.append('securityCode', requestBody.securityCode);
        formData.append('hasWarranty', requestBody.hasWarranty);
        formData.append('itemDescription', requestBody.itemDescription);
        formData.append('productType', requestBody.productType);
        formData.append('model', requestBody.model);
        formData.append('device', requestBody.device);
        formData.append('brand', requestBody.brand);

        // Append files (images) to FormData, if any
        if (requestBody.images && requestBody.images.length > 0) {
            requestBody.images.forEach((image, index) => {
                formData.append(`images`, image);  // Attach each file
            });
        }

        // Append imageIds to FormData (assuming it's an array)
        if (requestBody.imageIds && requestBody.imageIds.length > 0) {
            requestBody.imageIds.forEach((id, index) => {
                formData.append(`imageIds`, id);  // Attach each imageId
            });
        }

        // Make API call using FormData
        const response = await callApiWithAuthentication({
            method: "post",
            url: `${window.API_URL}/v2/service-item`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'  // Make sure the content type is multipart/form-data
            }
        });

        return response;

    } catch (error) {
        console.error('API call error:', error);
        throw error;
    }
}

export async function repairCreateRecurringCustomer(requestBody) {
    try {
        // Create a FormData object
        const formData = new FormData();

        // Append text fields to FormData
        formData.append('idCustomer', requestBody.idCustomer);
        formData.append('description', requestBody.description);
        formData.append('price', requestBody.price);
        formData.append('payInAdvanceAmount', requestBody.payInAdvanceAmount);
        formData.append('serialNumber', requestBody.serialNumber);
        formData.append('securityCode', requestBody.securityCode);
        formData.append('type', requestBody.type);

        // Append files (images) to FormData, if any
        if (requestBody.images && requestBody.images.length > 0) {
            requestBody.images.forEach((image, index) => {
                formData.append(`images`, image);  // Attach each file
            });
        }

        // Append imageIds to FormData (assuming it's an array)
        if (requestBody.imageIds && requestBody.imageIds.length > 0) {
            requestBody.imageIds.forEach((id, index) => {
                formData.append(`imageIds`, id);  // Attach each imageId
            });
        }

        // Make API call using FormData
        const response = await callApiWithAuthentication({
            method: "post",
            url: `${window.API_URL}/service-item/recurring-customer`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'  // Make sure the content type is multipart/form-data
            }
        });

        return response;

    } catch (error) {
        console.error('API call error:', error);
        throw error;
    }
}


export const repairAddComment = async (idRepair, comments) => {
    try {
        const response = await callApiWithAuthentication({
            method: "post",
            url: `${window.API_URL}/service-item/${idRepair}/comment`,
            data: {
                comments: comments,
            }
        });
        return response
    } catch (error) {
        throw error;
    }
};
