import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import FormControl from "../../components/UI/FormControl";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {Dropdown} from "primereact/dropdown";
import LoadingAnimation from "../../components/UI/LoadingAnimation/LoadingAnimation";
import {userGetDetails} from "../../services/UserService";
import {useTranslation} from "react-i18next";
import {storeConfigurationUpdate} from "../../services/StoreConfigurationService";

export default function Settings(props) {

    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const [storeConfiguration, setStoreConfiguration] = useState(false);
    const {t, i18n} = useTranslation();

    const fetchData = async () => {
        try {
            setLoading(true)
            const userDetails = await userGetDetails()
            setStoreConfiguration(userDetails.store.configuration)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alert('Failed to fetch user data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const formSubmit = async (e) => {
        e.preventDefault()

        try {
            const response = await storeConfigurationUpdate(storeConfiguration);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong'),
                life: 3000,
                position: "bottom"
            });
            return
        }

        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Configurations updated successfully'),
            life: 3000
        });
    }

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <Panel header={"Ρυθμίσεις"} className={"relative"}>
            <form onSubmit={(e) => {
                return formSubmit(e)
            }} className={"flex flex-wrap "}>
                <div className={"w-1/2 pr-5"}>
                    <FormControl label={t("Delivery Receipt Printing Type")} required={true}>
                        <Dropdown
                            optionLabel="label"
                            options={[
                                {label: t("No Printing"), value: "none"},
                                {label: "A4", value: "a4"},
                                {label: "A5", value: "a5"},
                            ]}
                            value={storeConfiguration.adminPanelPrintingType}
                            onChange={(e) => {
                                setStoreConfiguration((prevConfig) => ({
                                    ...prevConfig,
                                    adminPanelPrintingType: e.target.value,
                                }));
                            }}
                        />
                    </FormControl>
                </div>
                <div className={"w-1/2"}>
                    <FormControl label={t("Does your company work with devices that have security code?")}
                                 required={true}>
                        <Dropdown
                            options={[
                                {label: t("Yes"), value: true},
                                {label: t("No"), value: false},
                            ]}
                            optionLabel="label"
                            value={storeConfiguration.hasSecurityCode}
                            onChange={(e) => {
                                setStoreConfiguration((prevConfig) => ({
                                    ...prevConfig,
                                    hasSecurityCode: e.target.value,
                                }));
                            }}
                        />
                    </FormControl>
                </div>


                <div className={"w-1/2 pr-5"}>

                    <FormControl label={t("Does your company work with devices that have Serial Number or IMEI?")}
                                 required={true}>
                        <Dropdown
                            options={[
                                {label: t("Yes"), value: true},
                                {label: t("No"), value: false},
                            ]}
                            optionLabel="label"
                            value={storeConfiguration.hasSerialNumber}
                            onChange={(e) => {
                                setStoreConfiguration((prevConfig) => ({
                                    ...prevConfig,
                                    hasSerialNumber: e.target.value,
                                }));
                            }}/>
                    </FormControl>
                </div>
                <div className={"w-1/2 "}>
                    <FormControl label={t("Does your company have more than one technicians?")} required={true}>
                        <Dropdown
                            options={[
                                {label: t("Yes"), value: true},
                                {label: t("No"), value: false},
                            ]}
                            optionLabel="label"
                            value={storeConfiguration.hasTechnicians}
                            onChange={(e) => {
                                setStoreConfiguration((prevConfig) => ({
                                    ...prevConfig,
                                    hasTechnicians: e.target.value,
                                }));
                            }}/>
                    </FormControl>
                </div>

                <div className={"w-1/2 pr-5"}>
                    <FormControl label={t("Does your company do repairs under warranty?")} required={true}>
                        <Dropdown
                            options={[
                                {label: t("Yes"), value: true},
                                {label: t("No"), value: false},
                            ]}
                            optionLabel="label"
                            value={storeConfiguration.hasWarranty}
                            onChange={(e) => {
                                setStoreConfiguration((prevConfig) => ({
                                    ...prevConfig,
                                    hasWarranty: e.target.value,
                                }));
                            }}/>
                    </FormControl>
                </div>

                <div className={"w-1/2 pr-5"}>
                    <FormControl label={t("Repair products format")} required={true}>
                        <Dropdown
                            options={[
                                {label: t("Simple Description"), value: false},
                                {label: t("Device-Brand-Model"), value: true},
                            ]}
                            optionLabel="label"
                            value={storeConfiguration.hasDeviceBrandModelProducts}
                            onChange={(e) => {
                                setStoreConfiguration((prevConfig) => ({
                                    ...prevConfig,
                                    hasDeviceBrandModelProducts: e.target.value,
                                }));
                            }}/>
                    </FormControl>
                </div>

                <div className={"text-right w-full"}>
                    <Button type="submit" icon="pi pi-save" className={"text-lg"} label={t("Save")}
                            onClick={formSubmit}/>
                </div>
            </form>
            <LoadingAnimation state={loading}/>
        </Panel>
    </>

}
