import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {PlusIcon} from "primereact/icons/plus";
import {AiFillEdit} from "react-icons/ai";
import {TrashIcon} from "primereact/icons/trash";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {technicianDelete, techniciansGetList} from "../../services/TechnicianService";
import {deviceTypeDelete, deviceTypesGetList} from "../../services/DeviceTypeService";
import {brandsDelete, brandsGetList} from "../../services/BrandsService";
import {modelsDelete, modelsGetList} from "../../services/ModelsService";

export default function ModelList(props) {

    const toast = useRef(null);
    const [models, setModels] = useState([]);

    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState("id,desc");
    const [size, setSize] = useState(50);
    const [first, setFirst] = useState(0);
    const [loading, setLoading] = useState(true);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const fetch = async () => {
        setLoading(true)
        try {
            const fetchResult = await modelsGetList(page, size, sort);
            setModels(fetchResult.content);
            setTotalRecords(fetchResult.totalElements);
            setLoading(false);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetch();
    }, [page, sort, size]);

    const deleteAction = async (object) => {
        setLoading(true)
        try {
            await modelsDelete(object.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Model deleted successfully'),
            life: 3000,
            position: "bottom"
        });
        fetch()
    }

    const closeModal = () => {
    }

    const confirmDelete = (object) => {
        confirmDialog({
            message: t("Are you sure you'd like to delete this model?"),
            header: t("Delete Model"),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                deleteAction(object)
            },
            reject: () => {
                closeModal()
            }
        });
    }

    const getHeader = () => {
        return <div className={"flex items-center justify-between w-full"}>
            <span>{t("Models")}</span>
            <Button icon={<PlusIcon color={"white"} className={"mr-2"}/>} onClick={() => {
                navigate("/models/create")
            }} label={t("New Model")}/>
        </div>
    }

    const getActionsButtons = (object) => {
        return <>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltip={t("Edit Model")}
                    tooltipOptions={{position: 'bottom'}} icon={<AiFillEdit color={"grey"} onClick={() => {
                navigate(`/models/${object.id}/edit`)
            }
            }/>}/>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                    onClick={() => {
                        confirmDelete(object)
                    }}
                    tooltip={t("Delete Model")} icon={<TrashIcon color={"grey"}/>}/>
        </>
    }
    const onPage = (e) => {
        setPage(e.page);
        setFirst(e.first);
        setSize(e.rows);
    }

    const onSort = (e) => {
        setSort(`${e.sortField},${e.sortOrder === 1 ? "desc" : "asc"}`)
    }

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <ConfirmDialog/>
        <Panel header={getHeader()}>
            <DataTable
                value={models} showGridlines
                stripedRows className={"w-full"} size={"small"} paginator
                totalRecords={totalRecords} lazy loading={loading} onSort={onSort}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate={t("Total") + " : {totalRecords} " + t("Models")}
                first={first} onPage={onPage} rows={size} pageCount={5} rowsPerPageOptions={[5, 10, 25, 50]}
                emptyMessage={t("No models found")}
                dataKey="id"
            >
                <Column field="value" header={t("Model")}/>
                <Column field="actions" header="Ενέργειες" body={getActionsButtons}/>
            </DataTable>
        </Panel>
    </>;
}
