import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FormControl from "../../components/UI/FormControl";
import {customersGetDetails, customerUpdate} from "../../services/CustomerService";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {userGetDetails} from "../../services/UserService";

export default function CustomerEdit(props) {

    const {idCustomer} = useParams();
    const [customer, setCustomer] = useState(null);
    const [storeConfiguration, setStoreConfiguration] = useState(false);

    const fetchStoreConfiguration = async () => {
        try {
            const userDetails = await userGetDetails()
            setStoreConfiguration(userDetails.store.configuration)
        } catch (error) {
            alert('Failed to fetch user data:', error);
        }
    }

    const fetchCustomer = async () => {
        try {
            const customerData = await customersGetDetails(idCustomer);
            setCustomer(customerData);
        } catch (error) {
            alert('Failed to fetch customer data:', error);
        }
    };

    useEffect(() => {
        fetchStoreConfiguration();
        fetchCustomer();
    }, []);

    const formSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData(e.target)
        const data = {}
        formData.forEach((value, key) => (data[key] = value))

        try {
            const response = await customerUpdate(idCustomer, data);
        } catch (error) {
            alert("error");
            return
        }

        console.log(`redirect to /customer/list`)
        window.location.href = "/customer/list"
    }

    const getCountyCodePrefix = () => {
        return storeConfiguration.smsDefaultPrefix
    };

    return <Panel header={"Επεξεργασία Πελάτη"}>
        <form onSubmit={formSubmit}>
            <span className={"font-bold mb-4 block"}>ΥΠΕΥΘΥΝΟΣ ΕΠΙΚΟΙΝΩΝΙΑΣ</span>
            <div className={"flex gap-4"}>
                <FormControl label={"Όνομα"} required={true}>
                    <InputText placeholder={"Όνομα"} name={"name"}
                               defaultValue={customer != null ? customer.name : ""}/>
                </FormControl>
                <FormControl label={"Τηλέφωνο Επικοινωνίας Κινητό"} required={true}>
                    <div className="card flex justify-content-center">
                        <div className="p-inputgroup flex-1 w-full">
                            <span className="p-inputgroup-addon">+{getCountyCodePrefix()}</span>
                            <InputText placeholder={"Τηλέφωνο Επικοινωνίας Κινητό"} name={"phoneNumber1"}
                                       defaultValue={customer != null ? customer.phoneNumber1 : ""}/>
                        </div>
                    </div>
                </FormControl>
                <FormControl label={"Τηλέφωνο Επικοινωνίας Σταθερό"}>
                    <div className="card flex justify-content-center">
                        <div className="p-inputgroup flex-1 w-full">
                            <span className="p-inputgroup-addon">+{getCountyCodePrefix()}</span>
                            <InputText placeholder={"Τηλέφωνο Επικοινωνίας Σταθερό"} name={"phoneNumber2"}
                                       defaultValue={customer != null ? customer.phoneNumber2 : ""}/>
                        </div>
                    </div>
                </FormControl>
            </div>
            <span className={"font-bold mb-4 block"}>ΕΤΑΙΡΕΙΑ</span>
            <div className={"flex gap-4"}>
                <FormControl label={"Επωνυμία"}>
                    <InputText placeholder={"Επωνυμία Εταιρείας"} name={"companyName"}
                               defaultValue={customer != null ? customer.companyName : ""}/>
                </FormControl>
                <FormControl label={"Α.Φ.Μ."}>
                    <InputText placeholder={"Α.Φ.Μ."} name={"vatNumber"}
                               defaultValue={customer != null ? customer.vatNumber : ""}/>
                </FormControl>
                <FormControl label={"Δ.Ο.Υ."}>
                    <InputText placeholder={"Δ.Ο.Υ."} name={"doy"}
                               defaultValue={customer != null ? customer.doy : ""}/>
                </FormControl>
            </div>
            <div className={"flex gap-4"}>
                <FormControl label={"Διεύθυνση"}>
                    <InputText placeholder={"Διεύθυνση"} name={"address"}
                               defaultValue={customer != null ? customer.address : ""}/>
                </FormControl>
                <FormControl label={"Τ.Κ."}>
                    <InputText placeholder={"Τ.Κ."} name={"postCode"}
                               defaultValue={customer != null ? customer.postCode : ""}/>
                </FormControl>
                <FormControl label={"Πόλη"}>
                    <InputText placeholder={"Πόλη"} name={"city"}
                               defaultValue={customer != null ? customer.city : ""}/>
                </FormControl>
            </div>
            <div className={"flex justify-end"}>
                <Button label="Ενημέρωση Πελάτη"/>
            </div>
        </form>
    </Panel>;
}
